@import "../../../variables.scss";

.pageImage {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20px;

  > * {
    width: 30vh;
    max-width: 70%;
    max-height: 100%;
  }
}
