@import "../../../../variables.scss";

.radio {
  position: relative;
  width: 23px;
  height: 23px;
  background-color: transparent;
  border: 2px solid $secondary-text-color;
  display: inline-block;
  border-radius: 50%;

  &.active {
    color: $light-text-color;
    border-color: $active-text-color;

    &::after {
      content: "";
      background-color: $light-text-color;
      border-radius: 50%;
      position: absolute;
      inset: 3px;
    }
  }
}
