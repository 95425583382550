@import "../../../variables.scss";

@keyframes spin {

  0% {transform: rotateZ(0deg);}

  33% {transform: rotateZ(-120deg)}

  66% {transform: rotateZ(-240deg)}

  100% {transform: rotateZ(-360deg)}
}

.primary {
  fill: $primary-color;
}

.secondary {
  fill: $primary-text-color;
}

.alternative {
  fill: $main-text-color;
}

.disabled {
  fill: $disabled-color;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;

  svg {
    width: 13em;
    height: 13em;
    margin: 0 auto;
    animation: spin 5s;
    animation-iteration-count: infinite;
  }
}

.shimmerContainer {
  composes: loaderContainer;
  position: absolute;
  inset: 0;
}

.fullScreen {
  composes: loaderContainer;
  position: fixed;
  inset: 0;
  z-index: 2;
  opacity: .95;
  background-color: $background-color;
  padding: 0;

  svg {
    width: 30vw;
    height: 30vw;

    + span {
      margin-top: 50px;
    }
  }
}

.loaderMessage {
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  margin-top: $spacing-xs;
  color: $primary-color;
}

.shimmer {
  width: 100%;
  height: 100%;
  background: white;
}

.shimmer .imageCard {
  height: 100%;
  width: 100%;
}

.stroke {
  height: 15px;
  background: #777;
  margin-top: 20px;
}

.wrapper {
  width: 100%;
  height: 100%;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.animate {
  animation: shimmer 3s linear infinite;
  background: linear-gradient(to right, var(--shimmer-base-color) 5%, var(--shimmer-accent-color) 25%, var(--shimmer-base-color) 35%);
  background-size: 200% 100%;
}

@keyframes shimmer {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: -100% 0;
  }
}
