@import "../../variables.scss";

.MainContainer {
  background-color: $background-color;
  min-height: 100%;
  padding: $spacing-xs;

  &.flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.grid {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .fullRow {
    grid-column: 1 / 3;
  }

  .svgContainer {
    display: flex;
    place-content: center center;
    background-color: $background-color;

    svg {
      min-width: calc(100vw / 2.5);
      width: calc(100vw / 2.5);
      height: 100%;
    }
  }

  .MainText {
    color: $main-text-color;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: $spacing-xs;

    h4 {
      margin: $spacing-xs 0;
      line-height: $spacing-sm;
    }
  }

  .ButtonsContainer {

    &:not(:first-of-type) {
      margin-top: $spacing-xs;
    }

    button {
      margin-top: $spacing-xxs;
    }

    .buttonDescription {
      font-size: $font-size-xs;
      line-height: 18px;
      color: #858585;
    }
  }
}
