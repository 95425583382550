@import "../../../variables.scss";

$border-radius: 50px;
$loading-indicator-size: 36px;

.default {
  color: $dark-text-color;
  border: 0;
  border-radius: $border-radius;
  font-size: 18px;
  line-height: 18px;
  width: 100%;
  max-width: 400px;
  min-height: 50px;
  margin: 0;
  padding: 15px 18px;
  position: relative;
  align-self: center;
  cursor: none;

  &:active:not(.active) .shade,
  &:focus:not(.active) .shade,
  &.loading .shade,
  &.ellipsis .shade,
  &.disabled .shade {
    display: block;
    pointer-events: none;
  }
}

.bold {
  font-weight: bold;
}

.primary {
  composes: bold;
  background-color: $primary-color;
  color: $primary-text-color;
}

.secondary {
  background-color: $secondary-color;
  color: $secondary-text-color;
  border-color: $primary-color;
  border-width: 2px;
  border-style: solid;
}

.active {
  background-color: $active-color;
  color: $active-text-color;
}

.disabled {
  background-color: $disabled-color;
  color: $disabled-text-color;
}

.loading {
  pointer-events: none;

  :global {

    .btn-text {
      display: none;
    }
  }
}

.success {
  background-color: $success-color;
  color: $active-text-color;
}

.danger {
  background-color: $danger-color;
  color: $active-text-color;
}

.ellipsis {
  pointer-events: none;

  &::after {
    content: "...";
  }
}

.increasePadding {
  padding-right: 38px;
  padding-left: 38px;
}

.shade {
  display: none;
  position: absolute;
  inset: 0;
  background-color: $grey-light;
  opacity: .25;
  border-radius: $border-radius;
}

.icon {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  width: initial;
  height: initial;
}

.primaryColoredIcon {
  composes: icon;
  color: $primary-color
}

.square {
  border-radius: 0;
}

.loadingIndicator {
  position: absolute;
  left: calc(50% - #{$loading-indicator-size / 2});
  top: calc(50% - #{$loading-indicator-size / 2});
  width: $loading-indicator-size;
  height: $loading-indicator-size;

  svg {
    width: $loading-indicator-size;
    height: $loading-indicator-size;
  }
}

.leftImage {
  display: inline-flex;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;

  svg {
    max-width: 100%;
  }
}
