/*
we are currently using only what we need from
https://github.com/FortAwesome/Font-Awesome/blob/master/js-packages/%40fortawesome/fontawesome-svg-core/styles.css

NOTE: if you need more, just add it.
*/

:global {

  .svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
  }

  svg:not(:root).svg-inline--fa {
    overflow: visible;
  }

  .svg-inline--fa.fa-lg {
    vertical-align: -.225em;
  }

  .fa-lg {
    font-size: 1.3333em;
    line-height: .75em;
    vertical-align: -.0667em;
  }

  .fa-2x {
    font-size: 2em;
  }

  .fa-3x {
    font-size: 3em;
  }

  .fa-6x {
    font-size: 6em;
  }

  .fa-star {
    font-size: 2.5em;
  }

  .svg-inline--fa .fa-secondary {
    fill: var(--fa-secondary-color, currentColor);
    opacity: .4;
    opacity: var(--fa-secondary-opacity, .4);
  }

  .svg-inline--fa .fa-primary {
    fill: var(--fa-primary-color, currentColor);
    opacity: 1;
    opacity: var(--fa-primary-opacity, 1);
  }
}
